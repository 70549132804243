define([
    'santa-components',
    'lodash',
    'react',
    'prop-types',
    'create-react-class',
    'wixappsCore/proxies/appLinkProxy'
], function (santaComponents, _, React, PropTypes, createReactClass, appLinkProxy) {
    'use strict';

    const cmp = createReactClass({
        displayName: 'FlexboxContainerAnchor',
        propTypes: {
            href: PropTypes.string.isRequired,
            pathToItems: PropTypes.string,
            target: PropTypes.string,
            children: PropTypes.node
        },
        render() {
            return santaComponents.utils.createReactElement(
                'div',
                _.omit(this.props, 'href'),
                santaComponents.utils.createReactElement('a', {
                    'data-page-item-context': this.props.pathToItems,
                    href: this.props.href,
                    ref: 'a',
                    style: {// The style is required to avoid issues with flex-box children.
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        width: '100%'
                    },
                    target: this.props.target
                }, this.props.children)
            );
        }
    });
    const reactClass = React.createElement.bind(null, cmp);
    reactClass.type = cmp;

    return {
        mixins: [appLinkProxy],
        getCustomZoomReactClass() {
            return reactClass;
        }
    };
});
